<template>
  <div class="landing-layout min-h-svh">
    <NavBar />
    <div>
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script setup>
const user = useSupabaseUser();
const profileStore = useProfileStore();
const donationsPolling = useDonationsPolling();
const Sentry = useSentry();
const { $posthog } = useNuxtApp();

watch(
  user,
  () => {
    if (user.value) {
      setSentryUser();
      setPostHogUser();
      loadProfile();
      donationsPolling.enablePolling();
    }
  },
  { immediate: true }
);

function loadProfile() {
  profileStore.fetchProfile();
}

function setSentryUser() {
  if (!Sentry) {
    console.log("Sentry not initialized");
    return;
  }
  Sentry.setUser({ id: user?.value?.id, email: user?.value?.email });
}

function setPostHogUser() {
  if (!$posthog) {
    console.log("PostHog not initialized");
    return;
  }
  $posthog().identify(user?.value?.id, { email: user?.value?.email });
}
</script>

<style>
.landing-layout {
  background-color: #f3f3f1;
  padding-bottom: 50px;
}
</style>
